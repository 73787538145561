<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Шүүлтүүр
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row class="d-flex ">
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Огноо</label>
          <!-- <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="roleFilter"
            :options="roleOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:roleFilter', val)"
          /> -->
          <flat-pickr v-model="filtered_date" class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d' }"/>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Нэхэмжлэхийн дугаар</label>
          <b-form-input
                v-model="invoice_number"
                class="d-inline-block mr-1"
                placeholder="1234-5678-9100"
              />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <b-button
            variant="outline-primary"
            @click="$emit('update:qpayFilter', { filtered_date, invoice_number})"
            class="_report_submit_button"
          >
            <span class="text-nowrap">Qpay хайх</span>
          </b-button>
          <b-button
            variant="primary"
            @click="checkQpay()"
            class="_report_submit_button"
          >
            <span class="text-nowrap">Qpay шалгах</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    flatPickr,
    BFormInput
  },
  watch: {
  },
  props: {
    qpayFilter: {
      type: Object,
      default: null,
    }
  },
  data () {
    return {
      filtered_date: new Date().toISOString().substring(0,10),
      invoice_number: ''
    }
  },
  methods: {
    checkQpay() {
      if (!this.invoice_number || this.invoice_number === undefined || this.invoice_number === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Нэхэмжлэхийн дугаар хоосон байна',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      store.dispatch('app-qpay/checkQpay', this.invoice_number)
      .then(response => {
        if (response.data.success) {
          if(response.data.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Амжилттай төлөлт бүртгэлээ',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Өмнө нь шалгасан нэхэмжлэх байна',
                icon: 'BellIcon',
                variant: 'warn',
              },
            })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Qpay шалгах үед алдаа гарлаа',
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      })
    }
    // setReportName() {
    //   let hours = (new Date()).getHours() <= 8 ? 8 : (new Date()).getHours()
    //   if (this.reportNameOptions.length > 0) {
    //     this.report_name = this.reportNameOptions[0].name
    //     this.start_date = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 0, 0).setHours(8))
    //     this.end_date = new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 59, 59).setHours(hours))
    //   }
    // }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
  ._report_submit_button {
    margin-top: 19px;
  }
</style>
