import axios from '@axios'
import baseConfig from '@/config/index'
const QPAY_SERVICE_PATH = baseConfig.QPAY_SERVICE_PATH

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    checkQpay(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(QPAY_SERVICE_PATH + '/qpay/checkQpay?invoiceId=' + payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchQpayData(ctx, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios
          .get(QPAY_SERVICE_PATH + '/qpay/listQpayByDateOrInvoiceNumber?filtered_date=' + payload.filtered_date + '&invoiceNumber=' + payload.invoice_number + '&page=' + payload.page + '&per_page=' + payload.per_page)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
