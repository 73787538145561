import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useQpaysList() {
  // Use toast
  const toast = useToast()

  const refReportDataTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'invoice_id', sortable: true, label: 'Нэхэмжлэхийн дугаар' },
    { key: 'amount', sortable: true, label: 'Мөнгөн дүн' },
    { key: 'check_success', sortable: true, label: 'Төлөв' },
    { key: 'created_at', sortable: true, label: 'Шалгасан огноо' }
  ]
  const perPage = ref(10)
  const totalOrganizations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const qpayFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refReportDataTable.value ? refReportDataTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrganizations.value,
    }
  })

  const refetchData = () => {
    refReportDataTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, qpayFilter], () => {
    refetchData()
  })

  
  const fetchQpayData = (ctx, callback) => {
    console.log('1111111111111111111', qpayFilter, currentPage, perPage)
    let payload = {
      filtered_date: qpayFilter.value.filtered_date, 
      invoice_number: qpayFilter.value.invoice_number, 
      page: currentPage.value, 
      per_page: perPage.value
    }
    store
      .dispatch('app-qpay/fetchQpayData', payload)
      .then(response => {
        const qpayList = response.data.data.items
        const total = response.data.data.total
        // const qpays = qpayList.map(x => (x.check_success) ? x.check_success_text = 'Төлөгдсөн' : (x.status === 'inactive') ? x.statusText = 'Идэвхигүй' : x.statusText = 'Хүлээгдэж буй')
        callback(qpayList)
        totalOrganizations.value = total
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching report data',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveOrganizationRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveOrganizationRoleIcon = role => {
    if (role === 'subscriber') return 'OrganizationIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'OrganizationIcon'
  }

  const resolveOrganizationStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }


  return {
    fetchQpayData,
    tableColumns,
    perPage,
    currentPage,
    totalOrganizations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refReportDataTable,

    resolveOrganizationRoleVariant,
    resolveOrganizationRoleIcon,
    resolveOrganizationStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    qpayFilter
  }
}
