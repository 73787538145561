<template>

  <div>

    <!-- <organization-list-add-new
      :is-add-new-organization-sidebar-active.sync="isAddNewOrganizationSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    /> -->

    <!-- Filters -->
    <!-- <organizations-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->
    <qpays-list-filters
      :qpay-filter.sync="qpayFilter"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Нэг хуудсанд</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> бичлэг харуулах</label>
          </b-col>

          <!-- Search -->
          <!-- <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="invoice_number"
                class="d-inline-block mr-1"
                placeholder="1234-5678-9100"
              />
              <b-button
                variant="primary"
                @click="exportToExcel()"
              >
                <span class="text-nowrap">Qpay шалгах</span>
              </b-button>
            </div>
          </b-col> -->
        </b-row>

      </div>
      <b-table
        ref="refReportDataTable"
        class="position-relative"
        :items="fetchQpayData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Role -->
        <template #cell(amount)="data">
          <div class="text-nowrap">
            {{ formatCurrency(data.item.amount) }}
            <!-- {{ data.item.amount }} -->
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(check_success)="data">
          <b-badge
            pill
            :variant="`light-${resolveOrganizationStatusVariant(data.item.check_success)}`"
            class="text-capitalize"
          >
            {{ data.item.check_success ? 'Төлөгдсөн': 'Төлөгдөөгүй' }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'organizations-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Дэлгэрэнгүй</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'organizations-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Засах</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Устгах</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Нийт {{ dataMeta.of }}-н {{ dataMeta.from }} - {{ dataMeta.to }} бичлэгийг  харуулж байна</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrganizations"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { formatCurrency, avatarText } from '@core/utils/filter'
import QpaysListFilters from './QpaysListFilters.vue'
import useQpaysList from './useQpaysList'
import qpayStoreModule from '../qpayStoreModule'
import OrganizationListAddNew from './OrganizationListAddNew.vue'

import { parseTime } from '@/utils'
import parse from 'uuid/dist/parse'

export default {
  components: {
    QpaysListFilters,
    OrganizationListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const QPAY_APP_STORE_MODULE_NAME = 'app-qpay'

    // Register module
    if (!store.hasModule(QPAY_APP_STORE_MODULE_NAME)) store.registerModule(QPAY_APP_STORE_MODULE_NAME, qpayStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QPAY_APP_STORE_MODULE_NAME)) store.unregisterModule(QPAY_APP_STORE_MODULE_NAME)
    })

    const isAddNewOrganizationSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Админ', value: 'admin' },
      { label: 'Эрхлэгч', value: 'author' },
      { label: 'Засварлагч', value: 'editor' },
      { label: 'Дэмжигч', value: 'maintainer' },
      { label: 'Дагагч', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Үндсэн', value: 'basic' },
      { label: 'Байгууллага', value: 'company1' },
      { label: 'Аж ахуй нэгж', value: 'enterprise' },
      { label: 'Баг', value: 'team' },
    ]

    const statusOptions = [
      // { label: 'Хүлээгдэж буй', value: 'pending' },
      { label: 'Идэвхитэй', value: 'true' },
      { label: 'Идэвхигүй', value: 'false' },
    ]

    const {
      fetchQpayData,
      tableColumns,
      perPage,
      currentPage,
      totalOrganizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refReportDataTable,
      refetchData,

      // UI
      resolveOrganizationRoleVariant,
      resolveOrganizationRoleIcon,
      resolveOrganizationStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      qpayFilter
    } = useQpaysList()

    // const exportToExcel = () => {
    //   store
    //     .dispatch('app-report/fetchReportData', {
    //       "report_name": qpayFilter.value.report_name,
    //       "params": [
    //       qpayFilter.value.start_date, 
    //       qpayFilter.value.end_date
    //       ]
    //   })
    //     .then(response => {
    //        import('@/vendor/Export2Excel').then(excel => {
    //           excel.export_json_to_excel({
    //             header: response.data.response.reportHeader,
    //             data: response.data.response.reportData.map(x => response.data.response.reportHeader.map(y => { 
    //               if (y.includes("_at")) {
    //                 return parseTime(x[y])
    //               } 
    //               // else if (y.includes("_date")) {
    //               //   return parseTime(x[y])
    //               // } 
    //               // else if (y.includes("_time")) {
    //               //   return parseTime(x[y])
    //               // }
    //               else {
    //                 return x[y]
    //               }
    //             })),
    //             filename: 'ticket-' + qpayFilter.value.start_date + "-" + qpayFilter.value.end_date,
    //             autoWidth: true, //Optional
    //             bookType: 'csv' //Optional
    //           })
    //         })
    //     })
    //     .catch((error) => {
    //       console.log(error)
    //     })
    // }

    return {

      // Sidebar
      isAddNewOrganizationSidebarActive,

      fetchQpayData,
      tableColumns,
      perPage,
      currentPage,
      totalOrganizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refReportDataTable,
      refetchData,
      // exportToExcel,
      formatCurrency,

      // Filter
      avatarText,

      // UI
      resolveOrganizationRoleVariant,
      resolveOrganizationRoleIcon,
      resolveOrganizationStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      qpayFilter
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
